@use "../base" as B;


.s-default{
    position: relative;
    margin: 112px 219px 216px 218px;

    @media screen and (min-width: $break-xl){ 
        margin: 112px 219px 216px 418px;
    }
    @media screen and (min-width: 1800px){ 
        margin: 112px 219px 216px 518px;
    }

    @media screen and (max-width: $break-lg){ 
        margin: 112px 119px 216px 118px;
    }

    @media screen and (max-width: $break-md){ 
        margin: 32px 18px 32px 18px;
    }
}