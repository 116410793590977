.e-form{
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @media screen and (max-width: $break-sm){ 
        height: 200px;
    }

    &__buttons{
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    &__label{
        display: block;
        color: var(--color-text-grey);
        font-size: 20px;
        text-align: center;
    }

    &__input{
        display: block;
        border: 1px solid var(--color-secondary);
        border-radius: 7px;
        text-align: center;
        padding: 10px;
    }
}