// Basic color palette provided by the design team for general layout
:root {
    --color-primary: #09213b;
    --color-secondary: #05081c;
    --color-background-black: #0B0D17;

    --color-primary-light: #FFFFFF;
    --color-secondary-light: rgb(218, 250, 223);

    
    //Basic colors for text
    --color-txt-primary: #000; 
    --color-txt-secondary: #FFFFFF;
    --color-txt-teritary:#01132e;
    --color-text-grey: #424242;
    --color-txt-f: #D9DBE1;

}




