@use "../base" as B;

.btn {
  cursor: pointer;
  display: block;
  font-size: 16px;
  border-radius: 38.5px;
 
  &--nav{
    font-weight: 400;
    padding: 12px 60px;
    background-color: var(--color-primary-light);
    color: var(--color-txt-teritary);
    margin-left: 150px;
    &:hover {
      color: var(--color-text-grey);
      background-color: var(--color-secondary-light);
      transition: background-color 0.5s;
    }
    @media screen and (max-width: $break-lg){ 
      margin-left: 0px;
    }

  }

  &--default{
    width: 238px;
    height: 68px;
    font-weight: 600;
    line-height: 32px;

    @media screen and (max-width: $break-md){ 
      margin: 30px auto 0px;
    }

    @media screen and (max-width: $break-xs){ 
      padding: 14px 85px;
      width: 100%;      
    }

  }

  &--white{
    background-color: var(--color-primary-light);
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    box-sizing: border-box;
    border: 2px solid var(--color-secondary);
    &:hover {
      color: var(--color-txt-secondary);
      background-color: var(--color-secondary);
      transition: background-color 0.2s;
    }
  }

  &--green{
    background-color: var(--color-secondary);
    color: var(--color-txt-secondary);
    &:hover {
      color: var(--color-txt-teritary);
      background-color: var(--color-primary-light);
      transition: background-color 0.3s;
    }
  }

  &--darkGreen{
    background-color: var(--color-secondary);
    color: var(--color-txt-secondary);
    &:hover {
      background-color: var(--color-green-dark);
      color: var(--color-txt-secondary);
      transition: background-color 0.3s;
    }
  }

  &--cookie{
    font-weight: 600;
    padding: 18px 65px;
    margin-left: 15px;
    @media screen and (max-width: $break-sm){ 
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
    }
  }

  &--modal{
    width: 100%;
    height: 68px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: 32px;
  }
}