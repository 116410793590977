.swiper-pagination-bullet{
    background: var(--color-primary-light)!important;

    &-active{
        background: var(--color-secondary)!important;
    }
}

.swiper-slide:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(14,13,13,.5);
    z-index: 2;
    pointer-events: none;
}