@use "../base" as B;


.e-img{
    vertical-align: bottom;
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 400px;

    &__fade{
        position: absolute;
        left: 0px;
    }

    &--logo{
        width: 122.4px;
        height: 27.46px;
        margin-bottom: 24px;
    }

    &--person{
        width:59px;
        height: 57.31px;
    }
}
