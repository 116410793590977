// Font path works best by reaching it with relative path from style.scss
@font-face {
	font-family: "Poppins";
	font-display: auto;
	src: url("assets/fonts/Poppins-Regular.ttf") format("ttf");
	src: url("assets/fonts/Poppins-Regular.eot") format("eot");
	src: url("assets/fonts/Poppins-Regular.woff") format("woff");
	src: url("assets/fonts/Poppins-Regular.woff2") format("woff2");
}

:root {
    --font-family-primary: "Poppins";    
}
