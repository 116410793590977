@use "../base" as B;


.c-stories{
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $break-lg){ 
        overflow-y: auto;
    }

    &__wrap{
        display: flex;
        flex-direction: column;   
        
        &--position{
            position: absolute;
            bottom: -35px;
            left: 550px;

            @media screen and (max-width: $break-lg){ 
                position: relative;
                bottom: 0px;
                left: 0px;
                flex-direction: row;   

            }
        }
    }

    &__card{
        position: relative;
        background-color: var(--color-primary-light);
        box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 35px 40px 35px 65px;
        margin-top: 32px;
        @media screen and (max-width: $break-lg){ 
            padding: 24px 35px 22px 58px;
            margin-right: 20px;
            margin-top: 20px;
        }
    }

    &__person{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        &_wrap{
            margin-left: 17px;
            @media screen and (max-width: $break-xs){ 
                margin-top: 10px;
            }
            
        }
    }
    

}