@use "../base" as B;


.s-stories{
    background: linear-gradient(180deg, #FFFFFF 0%, #D7FFDA 100%);
    padding: 110px 219px 110px 178px;
    position: relative;

    @media screen and (max-width: $break-md){ 
        padding: 40px;
    }

    @media screen and (max-width: $break-xs){ 
        padding: 30px 0px 38px 18px;
    }

    &__wrap{
        position: relative;
        width: fit-content;
        transform: translateX(-50%);
        margin: 0 auto;
        @media screen and (max-width: $break-lg){ 
            transform: translateX(0);
            width: auto;
        }

    }

    &__quoteSetUp:before{
        position: absolute;
        content:url("/dist/assets/images/storiesquote.svg");
        margin-top: -60px;
        margin-left: -64px;
        z-index: -1;
        @media screen and (max-width: $break-md){ 
            display: none;
        }
    }

    &__img{
        width: 24.36px;
        height: 18.92px;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 100px;
        margin-left: 30px;
        @media screen and (max-width: $break-md){ 
            margin-left: 25px;
        }
        @media screen and (max-width: $break-xs){ 
            margin-top: 70px;
        }
        
    }
   
}
