@use "../base" as B;


.e-h1{
    font-weight: 700;
    font-size: 70px;
    line-height: 78px;
    text-align: center;
    inline-size: 850px;
    color: var(--color-txt-secondary);

    @media screen and (max-width: $break-lg){ 
        font-size: 50px;
        inline-size: 450px;
        line-height: 50px;
    }

    @media screen and (max-width: $break-xs){ 
        line-height: 36px;
        font-size: 32px;
        inline-size: auto;
    }
   
    &--highlight{
        color: rgb(184, 226, 184);
    }
   
    
}

.e-h2{
    color: var(--color-txt-teritary);
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;

    @media screen and (max-width: $break-xs){ 
        font-size: 28px;
        line-height: 36px;
    }
}

.e-h3{
    &--stories {
        line-height: 56px;
        inline-size: 414px;
        position: relative;
        font-weight: 600;
        font-size: 48px;
        color: var(--color-text-grey);
        @media screen and (max-width: $break-lg){ 
            font-size: 40px;
        }
        @media screen and (max-width: $break-md){ 
            font-size: 36px;
        }
        @media screen and (max-width: $break-xs){ 
            font-size: 26px;
            line-height: 119.2%;
            inline-size: 220px;
        }

    }
    &--imgBlock{
        font-size: 60px;
        line-height: 60px;
        text-align: center;
        color: var(--color-txt-secondary);
        font-weight: 700;
        @media screen and (max-width: $break-lg){ 
            font-size: 45px;
            line-height: 50px;
        }   
        @media screen and (max-width: $break-xs){ 
            font-size: 32px;
            line-height: 100%;
        }
    }
}

.e-h4{
    font-weight: 600;
    &--grey{
        font-size: 18px;
        line-height: 34px;
        color: var(--color-text-grey);
        @media screen and (max-width: $break-xs){ 
            font-size: 12px;
            line-height: 14px;
        }
    }
    &--green {
        font-size: 38px;
        line-height: 46px;
        margin-bottom: 29px;
        color: var(--color-txt-teritary);
        @media screen and (max-width: $break-md){ 
            font-size: 30px;
        }
        @media screen and (max-width: $break-xs){ 
            font-size: 26px;
            line-height: 119.2%;
            margin-bottom: 20px;
        }
    }
    &--cookie{
        font-size: 22px;
        line-height: 34px;
        color: var(--color-txt-teritary);
        @media screen and (max-width: $break-xs){ 
            font-size: 18px;
            line-height: 14px;
        }
    }
}

.e-h5{
    display: inline-block;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-txt-secondary);
}