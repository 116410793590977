@use "../base" as B;

.c-twoRowBox{
    display: flex;
    flex-direction: row;

    &:not(:first-of-type){
        margin-top: 80px;
    }

    @media screen and (max-width: $break-md){ 
        flex-direction: column;
    }

    &__left{
        @media screen and (max-width: $break-md){ 
            text-align: center;
        }
        @media screen and (max-width: 360px){ 
            text-align: left;
        }
        &_imgOne{
            
            margin: 0 auto;
            width: 347.3px;
            @media screen and (max-width: $break-sm){ 
                width: 250px;
            }
            @media screen and (max-width: $break-xs){ 
                width: 174px;
            }   
        }
        &_imgTwo{
            position: absolute;
            top: 145px;
            left: 195px;
            width: 241.21px;
            max-height: 310px;
            @media screen and (max-width: $break-md){ 
                left: 55%;
            }
            @media screen and (max-width: $break-sm){ 
                left: 45%;
                width: 180px;
            }
    
            @media screen and (max-width: $break-xs){ 
                width: 144px;
                top: 90px;
            }
        }
    }

    &__right{
        margin-top: auto;
        margin-left: 170px;

        
        @media screen and (max-width: $break-md){ 
            margin-left: 100px;
        }

        @media screen and (max-width: $break-md){ 
            margin: 120px auto 0px;
        }
        @media screen and (max-width: $break-xs){ 
            width: 100%;
        }
    }
}