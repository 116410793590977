@use "../base" as B;


.c-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: var(--header-large);
    width: 30%;
    margin-left: 70%;
    padding-left: 34px;
    padding-right: 34px;

    @media screen and (max-width: $break-lg){ 
        padding-left: 16px;
        padding-right: 16px;
    }

    @media screen and (max-width: $break-sm){ 
        height: var(--header-small);
    }

    &__list{
        $root: &;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media screen and (max-width: $break-lg){ 
            width: 0;
            overflow: hidden;
            font-size: 16px;
            transition: 0.3s;
            position: fixed;
            height: 100vh;
            z-index: 5;
            top: 0;
            right: 0;
            background-color: var(--color-primary);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
        &_btn{
            display: none;
            cursor: pointer;
        }
        &--active{
            @media screen and (max-width: $break-lg){ 
                padding: 20px;
                width: 50%;
              }
              @media screen and (max-width: $break-xs){ 
                width: 70%;
              }
              #{$root}_btn{
                display: block;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px;
                margin-right: 20px;
                margin-top: 20px;
                @media screen and (min-width: $break-lg){ 
                    display: none;
                }
              }
        }
    }

    &__item{
        @media screen and (max-width: $break-lg){ 
            width: 100%;
            padding: 10px;
            margin-top: 50px;
          }
    }

    &__logo{
        padding-top: 9px;
        &_img{
            @media screen and (max-width: $break-sm){ 
                width: 102px;
                height: 23px;           
            }    
        }
    }
    &__btn{
        display: none;
        cursor: pointer;
        @media screen and (max-width: $break-lg){ 
            display: block;
            position: absolute;
            top: 33px;
            right: 20px;
        }
        @media screen and (max-width: $break-sm){ 
            top: 23%;
            max-height: 30%;
        }
    }

   
}
