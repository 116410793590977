@use "../base" as B;
.s-imgBlock{
    position: relative;
    height: auto; 
    @media screen and (max-width: $break-md){ 
        height: 443px      
    }

    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(14,13,13,0.2);
        z-index: 1;
        pointer-events: none;
    }
}