@use "../base" as B;


.e-p{
    font-weight: 400;
    font-size: 18px;
    color: var(--color-text-grey);

    &--default{
        margin-top: 6px;
        margin-bottom: 30px;
        inline-size: 450px; 
        line-height: 36px;

        @media screen and (max-width: $break-lg){ 
            inline-size: auto; 
        }

        @media screen and (max-width: $break-md){ 
            inline-size: 380px; 
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: $break-xs){ 
            font-size: 16px;
            margin-left: 0;
            inline-size: auto; 
            margin-bottom: 46px;
        }
    }

    &--imgBlock{
        line-height: 36px;
        color: var(--color-txt-secondary);
        padding-bottom: 30px;
        padding-top: 8px;
        width: 640px;
        @media screen and (max-width: $break-sm){ 
            width: auto;
        }

        @media screen and (max-width: $break-sm){ 
            padding-bottom: 8px;
            padding-top: 11px;        }
    }

    &--stories{
        line-height: 32px;
        inline-size: 373px;
        @media screen and (max-width: $break-xs){ 
            inline-size: 220px;
            font-size: 12px;
            line-height: 22px;
        }
    }

    &--person{
        font-size: 14px;
        line-height: 36px;
        @media screen and (max-width: $break-xs){   
            font-size: 10px;
            line-height: 12px;
        }
    }

    &--footer{
        font-size: 14px;
        line-height: 24px;
        inline-size: 244px;
        margin-bottom: 29px;
        color: var(--color-text-f);
        &--bottom {
            @media screen and (max-width: $break-xs){ 
                margin: 0 auto;
                margin-top: 43px;        
            }
        }
    }
}