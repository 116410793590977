@use "../base" as B;

.c-footer{
    color: var(--color-txt-f);
    background-color: var(--color-background-black);
    padding: 90px 140px 20px;
    @media screen and (max-width: $break-lg){ 
        padding-left: 80px;
        padding-right: 80px;
    }

    @media screen and (max-width: $break-md){ 
        padding-left: 18px;
        padding-right: 18px;
    }
  
    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        @media screen and (max-width: $break-md){ 
            flex-direction: column;
        }
        &--bottom{
            margin-top: 108px;
            @media screen and (max-width: $break-xs){ 
                margin-top: 50px;
                display: flex;
                flex-direction: column-reverse;
                text-align: center;
            }
        }
    }

    &__contentBlock{
        display: flex;
        flex-direction: column; 
        
    }

    &__contentBlock--active .c-footer__link {
        height: 100%;
        opacity: 1;
        pointer-events: all;
    }

    &__contentBlock--active .c-footer__openMenuIcon{
        transform: rotate(90deg);
        margin-left: 5px;
    }

    &__link{
        margin-bottom: 12px;
        margin-left: 5px;
        color: var(--color-text-f);
        @media screen and (max-width: $break-md){ 
            display: block;
            height: 0;
            opacity: 0;
            transition: 0.3s;
            pointer-events: none;
        }

        &--reachUs{
            margin-bottom: 24px;
        }
    }

    &__linkItem{
        position: relative;
        padding-left: 35px;
        padding-top: 5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-txt-f);

        &_logo{
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__icon{
        margin-right: 22px;
        padding: 5px;
    }
    &__rights::after{
        content: "|";
        margin: 0 10px;
        line-height: 24px;
    }

    &__wrapMobile{
        display: inherit;
        @media screen and (max-width: $break-md){ 
            margin-top: 42px;
        }
    }
    &__openMenuIcon {
        display: none;
        @media screen and (max-width: $break-md){ 
            display: inline-block;
            position: absolute;
            top: 0;
            margin-top: 15px;
            padding-left: 5px;
            width: 12px;
            height: 12px;
        }
    } 

    &__btn{
        position: relative;
        pointer-events:none; 
        cursor:default;
        height: fit-content;
        padding: 5px;
        margin-bottom: 24px;
        @media screen and (max-width: $break-md){ 
            display: block;
            cursor: pointer;
            pointer-events:all; 
        }
    }
}

