.c-modal {
    $root: &;
    overflow: hidden;
    height: 0;

    &--visible {
        display: block;
        transition: 0.3s;
        z-index: 5;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        #{$root}__overlay {
            z-index: 6;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.8);         
        }
    }

    &__box{
        background-color: var(--color-primary-light);
        height: 500px;
        width: 400px;
        z-index: 7;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid var(--color-secondary);
        border-radius: 20px;
        @media screen and (max-width: $break-sm){ 
            height: 400px;
            width: 80%;
        }
  
    }
}