.headroom {
    will-change: transform;
    transition: transform 200ms linear;
  }
  .headroom--pinned {
    transform: translateY(0%);
  }
  .headroom--unpinned {
    transform: translateY(-100%);
  }


  .headroom--not-top.headroom--unpinned {.c-navigation__list--active 
    {
      @media screen and (max-width: $break-lg)
           {  height: calc(100vh + var(--header-large));
          padding-top: var(--header-large); } 
         
    
   @media screen and (max-width: $break-sm)
        {  height: calc(100vh + var(--header-large));
          padding-top:var(--header-small); } 
        } 
      } 
  
      
  .headroom--not-top.headroom--unpinned {.c-navigation__list--active .c-navigation__list_btn

    {
      @media screen and (max-width: $break-lg)
           {  margin-top: var(--header-large); } 
         
    
   @media screen and (max-width: $break-sm)
        { margin-top: var(--header-small); } 
        } 
      } 

  
    