@use "../base" as B;

body {
    font-family: var(--font-family-primary);
}

a{
    text-decoration: none;
    color: var(--color-txt-secondary);
}

:root {
    --header-large: 100px;
    --header-small: 60px;
}