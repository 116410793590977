.c-imgBlock{
    position: relative;
    height: 100%;

    &__wrapper{
        width: 100%;
        display: flex;
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        padding-left: 18px;
        padding-right: 18px;
        z-index: 2;
    }
}