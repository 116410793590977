@use "../base" as B;

.s-hero{
   position: relative;
   padding-top: var(--header-large);
   background-color: var(--color-secondary); 
   @media screen and (max-width: $break-sm){ 
    padding-top: var(--header-small);
    }

     &__title{
      position: absolute;
      z-index: 2;
      margin: 0 auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

     &__button{
      margin: 0 auto;
      margin-top: 30px;
      display: block;
      width: 237px;
      padding: 18px 0;
      text-align: center;
      border-radius: 38.5px;
      font-weight: 700;
      line-height: 25px;
      background-color: var(--color-txt-teritary);
      color: var(--color-primary-light);

      @media screen and (max-width: $break-md){ 
        margin-top: 24px;
      }

      &:hover {
        color: var(--color-txt-teritary);
        background-color: var(--color-primary-light);
        transition: background-color 0.5s;
      }
     }
}